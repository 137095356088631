import * as types from "./MainLayout.constraints"



export const changeStateLoading=()=>({
  type: types.CHANGE_IS_LOADING
})

export const turnOffLoading=()=>({
  type: types.TURN_OFF_IS_LOADING
})


export const turnOnLoading=()=>({
  type: types.TURN_ON_IS_LOADING
})

