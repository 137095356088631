import "./BackTopComponent.css"
import Icon from "@ant-design/icons";

const BackTopComponent=()=>{
  return (
    <button className="button-backtop">
      <span></span>
      <span></span>
      <span></span>
      <span></span> ▲
    </button>
  )

}
export default BackTopComponent