
import MainLayout from "./layouts/mainlayout/MainLayout";

function App() {
  return (
   <MainLayout/>
  );
}

export default App;
